import * as React from "react";
import {
  WindowWithChatbot,
  ClientChatbotStore,
  ConversationProperties,
  ConversationIntent,
  IntentTypes,
} from "src/stores/chatbotStore/ClientChatbotStore";
import { BlossomNotificationQuery } from "__generated__/typedefs";
import { sendAnalytics } from "src/components/shared/CustomerNotifications/NotificationsWithDialog/NotificationQueryUtils";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkLayoutFlex, UitkLayoutFlexItem, UitkLayoutFlexItemProps } from "@egds/react-core/layout-flex";
import { UitkCard, UitkCardLink } from "@egds/react-core/cards";
import { observer, inject } from "mobx-react";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { CovidBannerVersions } from "./ReasonsToBelieveWrapper";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";

type Unpacked<T> = T extends (infer U)[] ? U : T;
type Link = Unpacked<BlossomNotificationQuery["notification"]["inlineNotification"]["links"]>;
type Body = Unpacked<BlossomNotificationQuery["notification"]["inlineNotification"]["body"]>;
type Action = NonNullable<Unpacked<NonNullable<Link>["actions"]>>;

interface ReasonsToBelieveCardProps {
  chatbot?: ClientChatbotStore;
  link?: Link;
  body?: Body;
  analyticsCallback?: (rfrr: string, linkName: string | null) => void;
  index: number;
  minWidth: UitkLayoutFlexItemProps["minWidth"];
  maxWidth: UitkLayoutFlexItemProps["maxWidth"];
  version: CovidBannerVersions;
}

interface CardProperties {
  id: string | null | undefined;
  url: string | undefined;
  actions: Action[] | null | undefined;
  text: string | undefined;
  subText: string;
  iconName: string;
}

interface ImgIconNameToUitkIconName {
  pencil: string;
  coin: string;
  chat: string;
}

const imgIconNameToUitkIconName: ImgIconNameToUitkIconName = {
  pencil: "mode_edit",
  coin: "monetization_on",
  chat: "chat",
};

export const ReasonsToBelieveCard: React.FC<ReasonsToBelieveCardProps> = inject("chatbot")(
  observer((props: ReasonsToBelieveCardProps) => {
    const { link, analyticsCallback, chatbot, maxWidth, minWidth, index, version, body } = props;
    const refundId = "openChatbot";
    const cancelTripId = "cancelChangeTrip";
    const manageYourTripId = "covidManageYourTrip";
    const reedemCreditCouponId = "covidRedeemCredits";

    const firstBodyItem = body?.items[0] as any;
    const secondBodyItem = body?.items[1] as any;
    const thirdBodyItem = body?.items[2] as any;

    const card: CardProperties = {
      id: link?.id || thirdBodyItem?.id,
      url: link?.uri?.value || thirdBodyItem?.uri?.value,
      actions: link?.actions || thirdBodyItem?.actions,
      text: link?.text || firstBodyItem?.text,
      subText: secondBodyItem?.text || "",
      iconName: thirdBodyItem?.icon?.id || "",
    };

    const useChatbot = card.id === refundId || card.id === cancelTripId || card.id === manageYourTripId;
    const isCouponCard = card.id === reedemCreditCouponId;
    const images = [
      "https://forever.travel-assets.com/flex/flexmanager/images/2020/07/09/calendar_busy.svg",
      "https://forever.travel-assets.com/flex/flexmanager/images/2020/07/09/voucher.svg",
      "https://forever.travel-assets.com/flex/flexmanager/images/2020/07/09/approved.svg",
      "https://forever.travel-assets.com/flex/flexmanager/images/2020/11/04/coupons_cut.svg",
    ];

    const imagesV3: any = {
      calendar_busy: "https://forever.travel-assets.com/flex/flexmanager/images/2020/07/09/calendar_busy.svg",
      coupons_cut: "https://forever.travel-assets.com/flex/flexmanager/images/2020/11/04/coupons_cut.svg",
      suitcase: "https://forever.travel-assets.com/flex/flexmanager/images/2020/11/20/suitcase.svg",
      quotes: "https://forever.travel-assets.com/flex/flexmanager/images/2022/07/01/quote.svg",
    };

    const refundIntent: ConversationIntent = {
      intent: IntentTypes.REFUND_STATUS,
    };
    const cancelIntent = {
      intent: IntentTypes.VIEW_BOOKING,
    };

    const conversationProperties: ConversationProperties = {
      launchPoint: "ReasonsToBelieve",
      pageName: "StoreFront",
    };

    /* istanbul ignore next */
    const onChatbotClick = async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (analyticsCallback) {
        sendAnalytics(card.actions as Action[], analyticsCallback);
      }
      if (chatbot && useChatbot) {
        const initialized = await chatbot.isVirtualAgentInitialized(window as WindowWithChatbot);
        if (initialized) {
          const intentMessage = refundId === card.id ? refundIntent : cancelIntent;
          const conversationStarted = await chatbot.startConversation(conversationProperties, intentMessage);
          if (!conversationStarted) {
            window.location.href = card.url ?? "#";
          }

          return;
        }
      }
      window.location.href = card.url ?? "#";
    };

    /* istanbul ignore next */
    const onClickSendAnalytics = () => {
      if (analyticsCallback) {
        sendAnalytics(card.actions as Action[], analyticsCallback);
      }
    };

    const cardV1 = (
      <UitkCard padded>
        <UitkLayoutFlex alignItems="center" space="six">
          <UitkLayoutFlexItem minWidth={48} shrink={0}>
            <div>
              <UitkFigure ratio={UitkFigureAspectRatioType.R1_1}>
                <UitkImage src={images[index]} alt={card.text} />
              </UitkFigure>
            </div>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem grow={1}>
            <UitkHeading tag="h3" size={5}>
              {card.text}
            </UitkHeading>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
        <UitkCardLink>
          <button type="button" onClick={onChatbotClick}>
            {card.text}
          </button>
        </UitkCardLink>
      </UitkCard>
    );

    const cardV2 = (
      <UitkCard border>
        <UitkSpacing padding={{ block: "four", inline: "three" }}>
          <div>
            <UitkLayoutFlex alignItems="center" justifyContent="space-between">
              <UitkLayoutFlexItem>
                <UitkHeading tag="h3" size={5}>
                  {card.text}
                </UitkHeading>
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
            <UitkSpacing padding={{ blockstart: "one" }}>
              <div>
                <UitkText size={200}>{card.subText}</UitkText>
              </div>
            </UitkSpacing>
          </div>
        </UitkSpacing>
        <UitkCardLink>
          <button type="button" onClick={onChatbotClick}>
            {card.text}
          </button>
        </UitkCardLink>
      </UitkCard>
    );

    const cardV2WithCoupons = (
      <UitkCard border padded>
        <UitkSpacing padding={{ blockend: "two", blockstart: "two" }}>
          <UitkLayoutFlex>
            <UitkLayoutFlexItem minWidth={65} grow={1}>
              <div>
                <UitkFigure ratio={UitkFigureAspectRatioType.R1_1}>
                  <UitkImage src={images[3]} alt={card.text} />
                </UitkFigure>
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem grow={1}>
              <UitkSpacing padding={{ inlinestart: "two" }}>
                <div>
                  <UitkHeading tag="h3" size={5}>
                    {card.text}
                  </UitkHeading>
                  <UitkSpacing padding={{ blockstart: "one" }}>
                    <div>
                      <UitkText size={200}>{card.subText}</UitkText>
                    </div>
                  </UitkSpacing>
                </div>
              </UitkSpacing>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkSpacing>
        <UitkCardLink>
          <button type="button" onClick={onChatbotClick}>
            {card.text}
          </button>
        </UitkCardLink>
      </UitkCard>
    );

    const cardV3 = (
      <UitkCard border padded>
        <UitkSpacing padding={{ blockend: "two", blockstart: "two" }}>
          <UitkLayoutFlex alignItems={"center"}>
            <UitkLayoutFlexItem minWidth={48} shrink={0}>
              <div>
                <UitkFigure ratio={UitkFigureAspectRatioType.R1_1}>
                  <UitkImage src={imagesV3[card?.iconName]} alt="" role="presentation" />
                </UitkFigure>
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem shrink={1}>
              <UitkSpacing padding={{ inlinestart: "three" }}>
                <div>
                  <UitkHeading tag="h3" size={5}>
                    {card.text}
                  </UitkHeading>
                  <UitkSpacing padding={{ blockstart: "one" }}>
                    <div>
                      <UitkText size={300}>{card.subText}</UitkText>
                    </div>
                  </UitkSpacing>
                </div>
              </UitkSpacing>
            </UitkLayoutFlexItem>
            <UitkCardLink>
              <a href={card.url} target="_blank" rel="noopener noreferrer" onClick={onClickSendAnalytics}>
                {card.text}
              </a>
            </UitkCardLink>
          </UitkLayoutFlex>
        </UitkSpacing>
      </UitkCard>
    );

    const cardV4 = (
      <UitkCard border padded>
        <UitkSpacing padding={{ blockend: "one", blockstart: "one" }}>
          <UitkLayoutFlex>
            <UitkLayoutFlexItem shrink={1} minWidth="full_width">
              <div>
                <UitkHeading tag="h3" size={5}>
                  {card.text}
                </UitkHeading>
                <UitkSpacing padding={{ blockstart: "two" }}>
                  <div>
                    <UitkText size={200}>{card.subText}</UitkText>
                  </div>
                </UitkSpacing>
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem minWidth={16} maxWidth={16} shrink={0} alignSelf={"start"}>
              <UitkIcon
                name={imgIconNameToUitkIconName[card?.iconName as keyof ImgIconNameToUitkIconName]}
                size={UitkIconSize.SMALL}
              />
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkSpacing>
        <UitkCardLink>
          <button onClick={onChatbotClick}>{card.text}</button>
        </UitkCardLink>
      </UitkCard>
    );

    return (
      <UitkLayoutFlexItem grow={1} maxWidth={maxWidth} minWidth={minWidth}>
        {version === CovidBannerVersions.ONE && cardV1}
        {version === CovidBannerVersions.TWO && isCouponCard && cardV2WithCoupons}
        {version === CovidBannerVersions.TWO && cardV2}
        {version === CovidBannerVersions.THREE && cardV3}
        {version === CovidBannerVersions.FOUR && cardV4}
      </UitkLayoutFlexItem>
    );
  })
);
